<template>
  <div class="full-view bg-gray-light py-16 overflow-hidden">
    <div class="container">
      <h1 class="text-center" data-aos="fade-up">Gallery</h1>
    </div>
  </div>
  <div class="gallery my-24">
    <div class="mt-8 grid grid-cols-1 gap-8 md:grid-cols-3">
      <div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_5372.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_5374.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_9480.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_9481.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/Savannah-Terminal-2.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_4006.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_0119.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_5218.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_5222.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_5371.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
      </div>
      <div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_5375.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_3929.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_3938.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_3972.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_4005.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_3924.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_6070.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_6074.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_6082.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_9792.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
      </div>
      <div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_5435.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_5436.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_5452.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_5463.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_5465.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_9796.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_9797.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_E5221.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
        <div class="mb-8">
          <img
            src="@/assets/images/gallery/11_25/IMG_5868.jpg"
            loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
          />
        </div>
      </div>
    </div>
  </div>
</template>
