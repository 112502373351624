<template>
  <div class="contact">
    <div class="full-view bg-gray-light py-16 overflow-hidden">
      <div class="container">
        <h1 class="text-center" data-aos="fade-up">Contact Us</h1>
      </div>
    </div>
    <div class="my-24">
      <div class="container mx-auto block max-w-[500px]">
        <div class="info mb-12">
          <h3>Phone</h3>
          <a class="telephone" href="tel:9128005871"
            ><span class="text-xs">912-800-5871</span></a
          >
          <h3>Email</h3>
          <a class="email" href="mailto:contact@portsidetransportllc.com"
            ><span class="text-xs">contact@portsidetransportllc.com</span></a
          >
        </div>
        <div class="form-wrap">
          <h3 class="mb-4">Send us a Message</h3>
          <form
            class="flex flex-wrap"
            action="https://formspree.io/f/mqkjznvb"
            method="POST"
          >
            <div class="form-field basis-full">
              <label>Name</label>
              <input type="name" name="name" required />
            </div>
            <div class="form-field basis-full">
              <label>Your email</label>
              <input type="email" name="email" required />
            </div>
            <div class="form-field basis-full">
              <label>Message</label>
              <textarea name="message" required></textarea>
            </div>
            <div class="form-field basis-full">
              <button class="button primary" type="submit">Send</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
