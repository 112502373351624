<template>
    <section class="contact-section hidden">
        <div class="container">
            <h1>Contact Us</h1>
            <form action="https://formspree.io/f/mqkjznvb" method="POST">
                <label>Your email:
                    <input type="email" name="email">
                </label>
                <label>
                    Your message:
                    <textarea name="message"></textarea>
                </label>
                <!-- your other form fields go here -->
                <button type="submit">Send</button>
            </form>
        </div>
    </section>
</template>