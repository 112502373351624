<template>
    <header class="header">
        <div class="header-contact-wrapper row-full">
            <div class="header-contact container">
                <div class="row flex justify-end sm:justify-end">
                    <a class="basis-4/12 md:basis-auto md:pr-4 !m-0 telephone flex-item small" href="tel:9128005871">912-800-5871</a>
                    <a class="basis-8/12 md:basis-auto !m-0 email flex-item small text-right" href="mailto:contact@portsidetransportllc.com">contact@portsidetransportllc.com</a>
                </div>
            </div>
        </div>
        <div class="header-content container">
            <div class="header-content-wrapper flex justify-center items-center md:justify-end">
                <div class="basis-4/12 logo-wrapper">
                    <router-link to="/">
                        <img class="logo" loading="lazy" src="@/assets/images/logo.png" />
                    </router-link>
                </div>
                <nav class="mb-8 mt-4 grow basis-9/12 navigation flex justify-center gap-4 md:gap-8 md:justify-end md:mt-0 md:mb-0">
                    <router-link to="/"><span class="text-sm sm:text-base md:text-base">Home</span></router-link>
                    <router-link to="/services"><span class="text-sm sm:text-base md:text-base">Services</span></router-link>
                    <router-link to="/gallery"><span class="text-sm sm:text-base md:text-base">Gallery</span></router-link>
                    <router-link to="/contact"><span class="text-sm sm:text-base md:text-base">Contact</span></router-link>
                </nav>
            </div>
        </div>
    </header>
</template>
