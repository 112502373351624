import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// AOS
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({ duration: 750 });

createApp(App).use(router).mount("#app");
