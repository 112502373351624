<template>
    <section class="jobs-section hidden">
        <div class="container">
            <h1>Join our Team</h1>
            <p>Interested in joining our team?</p>
            <p>lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum </p>

            <form action="https://formspree.io/f/xjvzblng" method="POST">
                <label>
                    Your email:
                    <input type="email" name="email">
                </label>
                <label>
                    Your message:
                    <textarea name="message"></textarea>
                </label>
                <!-- your other form fields go here -->
                <button type="submit">Send</button>
            </form>
        </div>
    </section>
</template>