<template>
    <div class="services-wrapper bg-primary full-view py-24">
        <div class="container"
            data-aos="fade-up">
            <div class="flex justify-center items-center mb-12">
                <h2 class="text-center text-white divider-bottom-white pb-4 mb-4 inline-block mx-auto">Transport Services</h2>
            </div>
            <ul class="pl-4 list-none flex flex-wrap gap-8 justify-between items-center">
                <li class="basis-full sm:basis-auto">
                    <h5 class="text-white">Cement</h5>
                </li>
                <li class="basis-full sm:basis-auto">
                    <h5 class="text-white">Fly Ash</h5>
                </li>
                <li class="basis-full sm:basis-auto">
                    <h5 class="text-white">Sand</h5>
                </li>
                <li class="basis-full sm:basis-auto">
                    <h5 class="text-white">Gypsum</h5>
                </li>
            </ul>
        </div>
    </div>
</template>