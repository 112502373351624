<template>
  <GlobalHeader/>
  <main class="main content-main overflow-hidden">
    <div class="container">
      <router-view/>
    </div>
  </main>
  <GlobalFooter/>
</template>

<script>
import GlobalFooter from './layout/GlobalFooter.vue'
import GlobalHeader from './layout/GlobalHeader.vue'

export default {
components: {
    GlobalHeader,
    GlobalFooter
}
}
</script>

<style>
@import '@/assets/css/theme.css';
@import '@/assets/css/tailwind-output.css';
</style>
