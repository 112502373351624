<template>
    <footer class="footer">
        <div class="footer-wrapper section">
            <div class="footer-top">
            </div>
            <div
                class="footer-content row-contained">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <img style="margin-left: -15px; max-width: 170px;"
                        loading="lazy"
                        src="@/assets/images/logo.png"
                        alt="Portside Transport LLC"/>
                    </div>
                    <div class="col-12 col-md-4">
                        <p class="links-heading bold">Contact</p>
                        <ul class="link-items list-basic list-collapse">
                            <li class="link-item">
                                <a href="tel:9128005871">912-800-5871</a>
                            </li>
                            <li class="link-item">
                                <a href="mailto:contact@portsidetransportllc.com">contact@portsidetransportllc.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
