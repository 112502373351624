<template>
  <div class="home">
    <HeroComponent />
    <div class="wrapper">
      <div class="flex justify-center items-center py-24">
        <div class="basis-full max-w-[600px]" data-aos="fade-up">
          <h2 class="divider-bottom-secondary mb-4 pb-4">
            Simple Transport Solutions
          </h2>
          <p>
            Portside Transport is a Bulk Transportation company based in
            Savannah, Georgia. We provide simple and competitive transportation
            services to the Southeast, with an “On Time” delivery philosophy
            enabling customers to meet their objectives.
          </p>
        </div>
      </div>
    </div>
    <ServicesComponent />
    <CalloutsComponent />
    <ContactComponent />
    <JobsComponent />
  </div>
</template>

<script>
import ContactComponent from "../components/ContactComponent.vue";
import ServicesComponent from "../components/ServicesComponent.vue";
import CalloutsComponent from "../components/CalloutsComponent.vue";
import HeroComponent from "../components/HeroComponent.vue";
import JobsComponent from "../components/JobsComponent.vue";

export default {
  components: {
    ContactComponent,
    ServicesComponent,
    CalloutsComponent,
    HeroComponent,
    JobsComponent,
  },
};
</script>
